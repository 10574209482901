<template>
  <core-sidebar
    @hidden="handleHideEvent"
    @change.once="getStateFilterApi"
    sidebar-class="w-100 w-lg-25"
    id="jobs-filter"
    header-class="bg-aba-primary p-3"
    body-class="px-3 pt-3 d-flex flex-column"
    shadow
    backdrop
  >
    <template #header="{ hide }">
      <b-row align-v="center" class="flex-grow-1">
        <b-col class="d-flex">
          <span class="fs-5 fw-bolder text-white">
            <b-icon icon="sliders" class="mr-2"></b-icon>
            {{ translations[13590] }}
          </span>
        </b-col>
        <b-col cols="auto" class="d-flex ml-auto">
          <b-button class="bg-transparent border-0 p-1" @click="hide()">
            <b-icon icon="x" font-scale="1.5"></b-icon>
          </b-button>
        </b-col>
      </b-row>
    </template>
    <template #default="{ hide }">
      <h5 class="font-weight-light mb-3">{{ translations[13586] }}</h5>
      <b-tabs nav-class="mb-0 fs-6 d-flex w-100" no-nav-style>
        <b-tab active title-item-class="flex-fill h6" title-link-class="text-black px-0 py-1 fw-normal">
          <template #title>
            {{ translations[13422] }}
            <span v-b-tooltip.hover :title="translations[13601]" class="ml-2">
              <b-icon icon="info-circle" class="text-black-50" scale="0.8"></b-icon>
            </span>
          </template>
          <div class="mb-3">
            <!--            <select-profession v-model="selectProfession" :placeholder="translations[13602]" />-->
            <select-profession-update :similar-professions="true" v-model="selectProfession" :sm="true" :placeholder="translations[13602]" @change="[search(), getEmploymentCountApi()]" />
          </div>
        </b-tab>
        <b-tab title-item-class="flex-fill h6" title-link-class="text-black px-0 py-1 fw-normal">
          <template #title>
            {{ translations[13633] }}
            <span v-b-tooltip.hover :title="translations[13634]" class="ml-2">
              <b-icon icon="info-circle" class="text-black-50" scale="0.8"></b-icon>
            </span>
          </template>
          <div class="mb-3">
            <b-form-checkbox-group @change="[search(), getEmploymentCountApi()]" v-model="cluster" size="sm" switches stacked :options="clusters" value-field="id" text-field="name" />
          </div>
        </b-tab>
      </b-tabs>
      <div class="mb-3">
        <filter-section-title :title-text="translations[13603]" :tooltip-text="translations[13604]" />
        <select-location v-model="selectLocation" @change="[search(), getEmploymentCountApi()]" :placeholder="translations[13605]" />
      </div>
      <div class="mb-3">
        <filter-section-title :title-text="translations[13606]" :tooltip-text="translations[13607]" />
        <multiselect
          v-if="federalStateItems"
          class="multiselect-sm"
          label="location_name"
          track-by="id"
          v-model="selectedState"
          :options="federalStateItems"
          :placeholder="translations[13237]"
          :multiple="true"
          :internal-search="false"
          :clear-on-select="false"
          :close-on-select="false"
          :options-limit="300"
          open-direction="bottom"
          :showNoOptions="false"
          selectLabel=""
          selectGroupLabel=""
          deselectLabel=""
          deselectGroupLabel=""
          :showLabels="false"
          @remove="[search(), getEmploymentCountApi()]"
          @select="[search(), getEmploymentCountApi()]"
        >
          <template slot="noResult">
            <b>{{ translations[9506] }}</b>
          </template>
        </multiselect>
      </div>
      <div class="mb-3">
        <filter-section-title :title-text="translations[609]" :tooltip-text="translations[13617]" />
        <b-form-input @blur="[search(), getEmploymentCountApi()]" v-model="searchText" size="sm" :placeholder="translations[13724]" class="mb-2"></b-form-input>
        <b-form-select @change="[search(), getEmploymentCountApi()]" v-model="searchTextType" :options="searchTextTypeOptions" size="sm"></b-form-select>
      </div>
      <div class="mb-3">
        <filter-section-title :title-text="translations[13618]" :tooltip-text="translations[13619]" />
        <b-form-checkbox @change="search" size="sm" v-model="checkboxFullTime" switch>
          {{ translations[13620] }}
          (<span v-if="!loadingEmploymentCount">{{ localeString(employmentCount.fulltime) }}</span
          ><b-spinner v-else variant="secondary" small type="grow" label="Load"></b-spinner>)
        </b-form-checkbox>
        <b-form-checkbox @change="search" size="sm" v-model="checkboxPartTime" switch>
          {{ translations[13621] }}
          (<span v-if="!loadingEmploymentCount">{{ localeString(employmentCount.parttime) }}</span
          ><b-spinner v-else variant="secondary" small type="grow" label="Load"></b-spinner>)
        </b-form-checkbox>
        <b-form-checkbox @change="search" size="sm" v-model="checkboxPractice" switch>
          {{ translations[13622] }}
          (<span v-if="!loadingEmploymentCount">{{ localeString(employmentCount.praktikum) }}</span
          ><b-spinner v-else variant="secondary" small type="grow" label="Load"></b-spinner>)
        </b-form-checkbox>
      </div>
      <div class="mb-4">
        <filter-section-title :title-text="translations[12150]" :tooltip-text="translations[13623]">
          <template v-if="!isAuth">
            <span v-b-tooltip.hover :title="translations[13624]" class="ml-2">
              <b-icon class="text-black-50" icon="shield-lock" />
            </span>
          </template>
        </filter-section-title>
        <b-form-checkbox @change="search" :disabled="!isAuth" v-b-modal="!isAuth ? 'not-auth-modal' : ''" size="sm" v-model="checkboxSkillLevel2" switch>
          {{ translations[13625] }}
          (<span v-if="!loadingSkillCount">{{ localeString(employmentCount.skill_level2) }}</span
          ><b-spinner v-else variant="secondary" small type="grow" label="Load"></b-spinner>)
        </b-form-checkbox>
        <b-form-checkbox @change="search" :disabled="!isAuth" v-b-modal="!isAuth ? 'not-auth-modal' : ''" size="sm" v-model="checkboxSkillLevel3" switch>
          {{ translations[13626] }}
          (<span v-if="!loadingSkillCount">{{ localeString(employmentCount.skill_level3) }}</span
          ><b-spinner v-else variant="secondary" small type="grow" label="Load"></b-spinner>)
        </b-form-checkbox>
      </div>
      <filters-auth-alert v-if="!isAuth && !isHintDisabled" />
      <div class="bg-light bottom-0 mt-auto position-sticky py-3 z-3">
        <b-button @click="hide" variant="primary" size="sm" class="w-100">{{ jobsCount }} {{ translations[13632] }}</b-button>
      </div>
    </template>
  </core-sidebar>
</template>

<script lang="ts">
import { AppModule } from '@/store/modules/app';
import { UserModule } from '@/store/modules/user';
import { Vue, Component, Prop } from 'vue-property-decorator';
// eslint-disable-next-line import/named
import { ICountType, CountType, JobType } from '@/types/ProfessionType';
import FiltersAuthAlert from '@/components/FilterSidebar/FiltersAuthAlert.vue';
import FilterSectionTitle from '@/components/FilterSidebar/FilterSectionTitle.vue';
import SelectProfession from '@/components/Widget/SelectProfession.vue';
import SelectProfessionUpdate from '@/components/Widget/SelectProfessionUpdate.vue';
import SelectLocation from '@/components/Widget/SelectLocation.vue';
import { ProfessionModule } from '@/store/modules/profession';
import Multiselect from 'vue-multiselect';
import { FederalState } from '@/api/Profession';

@Component({
  components: {
    SelectProfession,
    SelectLocation,
    FilterSectionTitle,
    SelectProfessionUpdate,
    FiltersAuthAlert,
    Multiselect,
  },
})
export default class JobsFilter extends Vue {
  @Prop({ default: false }) private isWelcome!: boolean;

  tempSearchProfession = null;

  tempSearchLocation = null;

  tempSearchText = null as any;

  tempSearchFullTime = null as any;

  tempSearchPartTime = null as any;

  tempSearchPractice = null as any;

  tempSearchSkillLevel2: any;

  tempSearchSkillLevel3: any;

  tempSearchClusterTag: any;

  loadingEmploymentCount = false;

  loadingSkillCount = false;

  get jobType() {
    return ProfessionModule.jobType;
  }

  get isJobMap() {
    return this.jobType === JobType.map;
  }

  get jobsCount() {
    const count = ProfessionModule.jobs.amt;
    return count === undefined ? AppModule.setting?.all_count : count;
  }

  get employmentCount(): ICountType {
    return ProfessionModule.employmentCount;
  }

  get urlFilterParams() {
    return ProfessionModule.urlFilter;
  }

  get federalStateItems(): FederalState[] | null {
    return ProfessionModule.filterFederalStateItems;
  }

  get translations() {
    return AppModule.translation;
  }

  get isAuth() {
    return UserModule.isUserAuth;
  }

  get languageSid() {
    return AppModule.languageSid;
  }

  get isHintDisabled() {
    return !!AppModule.setting.job_search_hint_disable;
  }

  get clusters(): any[] {
    const counts: any = AppModule.jobCountPortal.clusters;
    return AppModule.settingPortal.clusters.map((v: any) => ({
      id: v.id,
      name: `${v.name} (${this.localeString(counts.find((x: any) => x.id === v.id)?.count || 0)})`,
    }));
  }

  // search professions
  get selectProfession() {
    this.tempSearchProfession = ProfessionModule.searchJobProfession;
    return ProfessionModule.searchJobProfession;
  }

  set selectProfession(value: any) {
    this.tempSearchProfession = value;
    ProfessionModule.SetSearchJobProfession(value);
    ProfessionModule.SetSearch({ obj: 'profession_id', value: value?.map((v: any) => v.id).join(',') || null });
  }
  // search professions

  // search locations
  get selectLocation() {
    this.tempSearchLocation = ProfessionModule.searchJobLocation;
    return ProfessionModule.searchJobLocation;
  }

  set selectLocation(value: any) {
    this.tempSearchLocation = value;
    ProfessionModule.SetSearchJobLocation(value);
    ProfessionModule.SetSearch({ obj: 'location_id', value: value?.id || null });
  }
  // search locations

  // full-text search
  get searchText() {
    // added tag Text
    this.tempSearchText = ProfessionModule.jobSearch.q;
    // end
    return ProfessionModule.jobSearch.q;
  }

  set searchText(value) {
    // added tag State
    this.tempSearchText = value;
    // end
    ProfessionModule.SetSearch({ obj: 'q', value });
  }
  // full-text search

  // full-text search mode
  get searchTextType() {
    return ProfessionModule.jobSearch.qmode;
  }

  set searchTextType(value) {
    ProfessionModule.SetSearch({ obj: 'qmode', value });
  }

  get searchTextTypeOptions() {
    return [
      {
        value: 1,
        text: this.translations[10436],
      },
      {
        value: 2,
        text: this.translations[10437],
      },
    ];
  }
  // full-text search mode

  // search full-time jobs
  get checkboxFullTime() {
    if (ProfessionModule.searchTags.fullTimeTag === 1) {
      this.tempSearchFullTime = ProfessionModule.jobFilter.fulltime;
    } else {
      this.tempSearchFullTime = !ProfessionModule.jobFilter.fulltime;
    }
    return !ProfessionModule.jobFilter.fulltime;
  }

  set checkboxFullTime(value: any) {
    this.tempSearchFullTime = Boolean(!value);
    ProfessionModule.SetFilter({ obj: 'fulltime', value: Number(!value) });
    ProfessionModule.SetSearchTags({ tag: 'fullTimeTag', value: false });
  }
  // search full-time jobs

  // search part-time jobs
  get checkboxPartTime() {
    if (ProfessionModule.searchTags.partTimeTag === 1) {
      this.tempSearchPartTime = ProfessionModule.jobFilter.parttime;
    } else {
      this.tempSearchPartTime = !ProfessionModule.jobFilter.parttime;
    }
    return !ProfessionModule.jobFilter.parttime;
  }

  set checkboxPartTime(value) {
    this.tempSearchPartTime = Boolean(!value);
    ProfessionModule.SetFilter({ obj: 'parttime', value: Number(!value) });
    ProfessionModule.SetSearchTags({ tag: 'partTimeTag', value: false });
  }
  // search part-time jobs

  // search internships jobs
  get checkboxPractice() {
    if (ProfessionModule.searchTags.practiceTag === 1) {
      this.tempSearchPractice = ProfessionModule.jobFilter.praktikum;
    } else {
      this.tempSearchPractice = !ProfessionModule.jobFilter.praktikum;
    }
    return !ProfessionModule.jobFilter.praktikum;
  }

  set checkboxPractice(value) {
    this.tempSearchPractice = Boolean(!value);
    ProfessionModule.SetFilter({ obj: 'praktikum', value: Number(!value) });
    ProfessionModule.SetSearchTags({ tag: 'practiceTag', value: false });
  }
  // search internships jobs

  // search skilled workers
  get checkboxSkillLevel2() {
    if (ProfessionModule.searchTags.skillLevel2Tag === 1) {
      this.tempSearchSkillLevel2 = ProfessionModule.jobFilter.skill_level2;
    } else {
      this.tempSearchSkillLevel2 = !ProfessionModule.jobFilter.skill_level2;
    }
    return !ProfessionModule.jobFilter.skill_level2;
  }

  set checkboxSkillLevel2(value) {
    this.tempSearchSkillLevel2 = Boolean(!value);
    ProfessionModule.SetFilter({ obj: 'skill_level2', value: Number(!value) });
    ProfessionModule.SetSearchTags({ tag: 'skillLevel2Tag', value: false });
  }
  // search skilled workers

  // search specialists / experts
  get checkboxSkillLevel3() {
    if (ProfessionModule.searchTags.skillLevel3Tag === 1) {
      this.tempSearchSkillLevel3 = ProfessionModule.jobFilter.skill_level3;
    } else {
      this.tempSearchSkillLevel3 = !ProfessionModule.jobFilter.skill_level3;
    }
    return !ProfessionModule.jobFilter.skill_level3;
  }

  set checkboxSkillLevel3(value) {
    this.tempSearchSkillLevel3 = Boolean(!value);
    ProfessionModule.SetFilter({ obj: 'skill_level3', value: Number(!value) });
    ProfessionModule.SetSearchTags({ tag: 'skillLevel3Tag', value: false });
  }
  // search specialists / experts

  get cluster() {
    const clusterStore = ProfessionModule.jobFilter.cluster_id;
    this.tempSearchClusterTag = ProfessionModule.jobFilter.cluster_id;
    return clusterStore ? [clusterStore] : [];
  }

  set cluster(value: any) {
    const param = value[value.length - 1] || 0;
    ProfessionModule.SetFilter({ obj: 'cluster_id', value: param });
    ProfessionModule.SetSearchTags({ tag: 'clusterTag', value: param });
  }

  // search state
  get selectedState() {
    return ProfessionModule.selectedFederalStateItems;
  }

  set selectedState(value) {
    ProfessionModule.SetSelectedFederalStates(value);
  }
  // search state

  get optionsLand() {
    return [
      { value: null, text: this.translations[13237] },
      { value: 0, text: `- ${this.translations[13608]} (123)` },
      { value: 1, text: `- ${this.translations[13609]} (123)` },
      { value: 2, text: `- ${this.translations[13610]} (123)` },
      { value: 3, text: `- ${this.translations[13611]} (123)` },
      { value: 4, text: `- ${this.translations[13612]} (123)` },
      { value: 5, text: `- ${this.translations[13613]} (123)` },
      { value: 5, text: `- ${this.translations[13614]} (123)` },
      { value: 5, text: `- ${this.translations[13615]} (123)` },
      { value: 5, text: `- ${this.translations[13616]} (123)` },
      { value: 5, text: `- ${this.translations[2013]} (123)` },
    ];
  }

  localeString(payload: number) {
    return payload ? payload.toLocaleString() : 0;
  }

  isSearchTriggered = false;

  get getUrlParams() {
    return !!this.$route.query?.filters;
  }

  handleHideEvent() {
    if (this.isSearchTriggered) {
      this.setUrlParams();
      this.isSearchTriggered = false;
    }
    if (!this.getUrlParams) {
      this.setUrlParams();
      AppModule.SetVisibleJobs(true);
    }
  }

  setUrlParams() {
    const currentQueryParams = { ...this.$route.query };
    currentQueryParams.filters = this.urlFilterParams;
    this.$router.replace({ query: currentQueryParams }).catch(() => {});
  }

  search() {
    this.isSearchTriggered = true;
    // this.getEmploymentCountApi();
    AppModule.SetVisibleJobs(false);
    AppModule.SetVisibleClusterJobs(false);
    AppModule.SetVisibleMap(false);
    this.$nextTick(() => {
      // search tags
      ProfessionModule.SetSearchJobProfessionTitle(this.tempSearchProfession);
      ProfessionModule.SetSearchJobLocationTitle(this.tempSearchLocation);
      // search filter tags
      ProfessionModule.SetSearchTags({ tag: 'textTag', value: this.tempSearchText });
      ProfessionModule.SetSearchTags({ tag: 'fullTimeTag', value: this.tempSearchFullTime });
      ProfessionModule.SetSearchTags({ tag: 'partTimeTag', value: this.tempSearchPartTime });
      ProfessionModule.SetSearchTags({ tag: 'practiceTag', value: this.tempSearchPractice });
      ProfessionModule.SetSearchTags({ tag: 'skillLevel2Tag', value: this.tempSearchSkillLevel2 });
      ProfessionModule.SetSearchTags({ tag: 'skillLevel3Tag', value: this.tempSearchSkillLevel3 });
      ProfessionModule.SetSearchTags({ tag: 'clusterTag', value: this.tempSearchClusterTag });
      // this.setUrlParams();
      AppModule.SetVisibleJobs(true);
      if (this.isWelcome) {
        AppModule.SET_WELCOME(true);
        this.$router.push({
          name: 'Jobs',
          query: { lang: AppModule.language },
        });
      }
    });
  }

  getEmploymentCountApi() {
    const defaultCountParams = {
      sid: this.languageSid,
      count: 1,
    };
    this.$nextTick(() => {
      if (this.isSearchTriggered || (this.employmentCount.fulltime == null || this.employmentCount.parttime == null || this.employmentCount.praktikum == null)) {
        this.loadingEmploymentCount = true;
        const load1 = ProfessionModule.GetJobsCount({
          filter: CountType.fulltime,
          params: {
            ...defaultCountParams,
            praktikum: 1,
            parttime: 1,
          },
        });
        const load2 = ProfessionModule.GetJobsCount({
          filter: CountType.parttime,
          params: {
            ...defaultCountParams,
            praktikum: 1,
            fulltime: 1,
          },
        });
        const load3 = ProfessionModule.GetJobsCount({
          filter: CountType.praktikum,
          params: {
            ...defaultCountParams,
            fulltime: 1,
            parttime: 1,
          },
        });

        Promise.all([load1, load2, load3])
          .finally(() => {
            this.loadingEmploymentCount = false;
          });
      }
      if (this.isSearchTriggered || (this.employmentCount.skill_level2 == null || this.employmentCount.skill_level3 == null)) {
        this.loadingSkillCount = true;
        const load1 = ProfessionModule.GetJobsCount({
          filter: CountType.skill_level2,
          params: {
            ...defaultCountParams,
            skill_level3: 1,
          },
        });
        const load2 = ProfessionModule.GetJobsCount({
          filter: CountType.skill_level3,
          params: {
            ...defaultCountParams,
            skill_level1: 1,
            skill_level2: 1,
          },
        });

        Promise.all([load1, load2])
          .finally(() => {
            this.loadingSkillCount = false;
          });
      }
    });
  }

  getStateFilterApi() {
    if (!this.federalStateItems?.length) {
      ProfessionModule.GetFederalStateItems({
        sid: this.languageSid,
      });
    }
    this.getEmploymentCountApi();
  }
}
</script>
