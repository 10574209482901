// eslint-disable-next-line import/named
import { ThemeSite, ThemeSids } from '@/types/ThemesType';

export const domainDatabase: ThemeSite[] = [
  // localhost default ---------------------------------
  {
    domain: 'localhost',
    sid: ThemeSids.ncph,
    color: '#FC9E16',
    // language: 'en',
    languageDropdown: true,
  },
  // localhost default ---------------------------------
  {
    domain: 'portal.workinaustria.com',
    sid: ThemeSids.aba,
    color: '#d83818',
    languageDropdown: true,
  },
  {
    domain: 'jobs.workinaustria.com',
    sid: ThemeSids.aba,
    color: '#d83818',
    languageDropdown: true,
  },
  {
    domain: 'work.workinaustria.com',
    sid: ThemeSids.abatest,
    color: '#d83818',
    languageDropdown: true,
  },
  {
    domain: 'jig.jobmatch.works',
    sid: ThemeSids.jig,
    color: '#E30613',
    languageDropdown: true,
    title: {
      de: 'Jobs in Germany - Talentpool',
      en: 'Jobs in Germany - Talentpool',
    },
  },
  {
    domain: 'jobs.jig.world',
    sid: ThemeSids.jig,
    color: '#E30613',
    languageDropdown: true,
    title: {
      de: 'Jobs in Germany - Talentpool',
      en: 'Jobs in Germany - Talentpool',
    },
  },
  {
    domain: 'talentpool.jobmatch.works',
    sid: ThemeSids.talentpool,
    color: '#CCD300',
    languageDropdown: true,
    title: {
      de: 'JobMATCH Talentpool',
      en: 'JobMATCH Talentpool',
    },
  },
  {
    domain: 'talentpool.edunet.works',
    sid: ThemeSids.edunet,
    color: '#FC9E16',
    languageDropdown: true,
    title: {
      de: 'Edunet Talentpool',
      en: 'Edunet Talentpool',
    },
  },
  {
    domain: 'talentpool.workconsult.at',
    sid: ThemeSids.wconat,
    color: '#bf1e2e',
    languageDropdown: true,
    title: {
      de: 'JobMATCH Talentpool',
      en: 'JobMATCH Talentpool',
    },
  },
  {
    domain: 'talentpool.nursingcareer.ph',
    sid: ThemeSids.ncph,
    color: '#006c68',
    language: 'en',
    languageDropdown: false,
    title: {
      de: 'JobMATCH Talentpool',
      en: 'JobMATCH Talentpool',
    },
  },
  {
    domain: 'work.talents4austria.com',
    sid: ThemeSids.t4a,
    color: '#bf1e2e',
    languageDropdown: true,
    title: {
      de: 'JobMATCH Talentpool',
      en: 'JobMATCH Talentpool',
    },
  },
];

export const getByDomain = (): ThemeSite | null => {
  const url = window.location.href;
  const parsedUrl = new URL(url);
  const domain = parsedUrl.hostname;

  const strippedDomain = domain.startsWith('www.') ? domain.slice(4) : domain;
  const site = domainDatabase.find((site) => site.domain === strippedDomain);
  return site || null;
};
