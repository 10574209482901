export enum TypeField {
  visible= 'visible',
  mandatory= 'mandatory'
}
export interface ISignin {
  id?: number
  username: string
  password: string
  sid: string
}
export interface IRegister {
  username: string
  password?: string
  email: string
  sid: string
  lang: string
  firstname?: string
  lastname?: string
  firmname?: string
}
export interface ILoginRequest {
  expires_in: any
  access_token: string
  refresh_token: string
}
export interface IUser {
  firstName: string
  lastName: string
  email: string
  phone?: string
}
export interface IProfileState {
  user?: IUser
  error: boolean
}
