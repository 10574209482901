<template>
  <div class="auth-cabinet">
    <header>
      <template v-if="groupTemplate">
        <component :is="`header-information-navigation-${sid}`" />
      </template>
      <information-navigation v-else />
    </header>
    <main>
      <b-container fluid class="pt-0 pb-5 p-0" id="content">
        <b-container>
          <b-row>
            <b-col cols="12">
              <router-view />
            </b-col>
          </b-row>
        </b-container>
      </b-container>
    </main>
    <template>
      <template v-if="groupTemplate">
        <component :is="`footer-component-${sid}`" />
      </template>
      <footer-component v-else />
    </template>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import InformationNavigation from '@/components/Header/InformationNavigation.vue';
import HeaderInformationNavigationJig from '@/components/Themes/HeaderInformationNavigationJig.vue';
import HeaderInformationNavigationTalentpool from '@/components/Themes/HeaderInformationNavigationTalentpool.vue';
import HeaderInformationNavigationT4a from '@/components/Themes/HeaderInformationNavigationT4a.vue';
import HeaderInformationNavigationNcph from '@/components/Themes/HeaderInformationNavigationNcph.vue';
import HeaderInformationNavigationWconat from '@/components/Themes/HeaderInformationNavigationWconat.vue';
import PagesNavigation from '@/components/Header/PagesNavigation.vue';
import FooterComponent from '@/components/FooterComponent.vue';
import FooterComponentJig from '@/components/Themes/FooterComponentJig.vue';
import FooterComponentTalentpool from '@/components/Themes/FooterComponentTalentpool.vue';
import { AppModule } from '@/store/modules/app';
import { UserModule } from '@/store/modules/user';
import HeaderInformationNavigationEdunet from '@/components/Themes/HeaderInformationNavigationEdunet.vue';
import FooterComponentT4a from '@/components/Themes/FooterComponentT4a.vue';
import FooterComponentNcph from '@/components/Themes/FooterComponentNcph.vue';
import FooterComponentWconat from '@/components/Themes/FooterComponentWconat.vue';
import FooterComponentEdunet from '@/components/Themes/FooterComponentEdunet.vue';
import { group } from '@/config/configuration';

@Component({
  components: {
    InformationNavigation,
    PagesNavigation,
    FooterComponent,
    HeaderInformationNavigationJig,
    HeaderInformationNavigationTalentpool,
    HeaderInformationNavigationEdunet,
    HeaderInformationNavigationT4a,
    HeaderInformationNavigationNcph,
    HeaderInformationNavigationWconat,
    FooterComponentJig,
    FooterComponentTalentpool,
    FooterComponentEdunet,
    FooterComponentT4a,
    FooterComponentNcph,
    FooterComponentWconat,
  },
})
export default class CabinetTemplate extends Vue {
  get sid() {
    return AppModule.sid;
  }

  get languageSid() {
    return AppModule.languageSid;
  }

  get accessTokenApplicant() {
    return UserModule.accessTokenApplicant;
  }

  get accessTokenConsultant() {
    return UserModule.accessTokenConsultant;
  }

  get accessTokenCompany() {
    return UserModule.accessTokenCompany;
  }

  get groupTemplate() {
    return group(this.sid);
  }
}
</script>
