import { render, staticRenderFns } from "./FooterComponentWconat.vue?vue&type=template&id=2f224dda&scoped=true&"
import script from "./FooterComponentWconat.vue?vue&type=script&lang=ts&"
export * from "./FooterComponentWconat.vue?vue&type=script&lang=ts&"
import style0 from "./FooterComponentWconat.vue?vue&type=style&index=0&id=2f224dda&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2f224dda",
  null
  
)

export default component.exports