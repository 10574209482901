import { render, staticRenderFns } from "./FooterComponentJig.vue?vue&type=template&id=e9546468&scoped=true&"
import script from "./FooterComponentJig.vue?vue&type=script&lang=ts&"
export * from "./FooterComponentJig.vue?vue&type=script&lang=ts&"
import style0 from "./FooterComponentJig.vue?vue&type=style&index=0&id=e9546468&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e9546468",
  null
  
)

export default component.exports