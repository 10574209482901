import { render, staticRenderFns } from "./WorkerSidebarNew.vue?vue&type=template&id=f578840e&scoped=true&"
import script from "./WorkerSidebarNew.vue?vue&type=script&lang=ts&"
export * from "./WorkerSidebarNew.vue?vue&type=script&lang=ts&"
import style0 from "./WorkerSidebarNew.vue?vue&type=style&index=0&id=f578840e&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f578840e",
  null
  
)

export default component.exports