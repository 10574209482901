import store from '@/store';
// eslint-disable-next-line import/named
import { ThemeSids, ThemeSite } from '@/types/ThemesType';
import {
  VuexModule, Module, getModule, Action, Mutation,
} from 'vuex-module-decorators';

export interface IThemesState {
  sample: ThemeSite
}

@Module({ dynamic: true, store, name: 'ThemesModule' })
class Themes extends VuexModule implements IThemesState {
  sample: ThemeSite = {
    sid: ThemeSids.aba,
    color: '#d83818',
    title: null,
    languageDropdown: true,
  };

  @Mutation
  SET_THEME(payload: ThemeSite) {
    this.sample = payload;
  }

  @Action
  public SetTheme(payload: ThemeSite) {
    this.SET_THEME(payload);
  }
}

export const ThemesModule = getModule(Themes);
