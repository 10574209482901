const sids = [
  'jig',
  'talentpool',
  'edunet',
  'wconat',
  'ncph',
  't4a',
];

export const group = (sid: string) => sids.includes(sid);
