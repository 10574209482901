export enum JobType {
  items,
  search,
  map,
  cluster,
}

export type ProfessionQuery = {
  description: string
  id: string
  name: string
}

export interface JobTags {
  textTag: any
  fullTimeTag: any
  partTimeTag: any
  practiceTag: any
  skillLevel2Tag: any
  skillLevel3Tag: any
  clusterTag: any
}

export interface ICountType {
  fulltime: number | null
  parttime: number | null
  praktikum: number | null
  skill_level2: number | null
  skill_level3: number | null
}

export enum CountType {
  fulltime = 'fulltime',
  parttime = 'parttime',
  praktikum = 'praktikum',
  skill_level2 = 'skill_level2',
  skill_level3 = 'skill_level3',
}
