import { render, staticRenderFns } from "./RightSidebar.vue?vue&type=template&id=58aee878&scoped=true&"
import script from "./RightSidebar.vue?vue&type=script&lang=ts&"
export * from "./RightSidebar.vue?vue&type=script&lang=ts&"
import style0 from "./RightSidebar.vue?vue&type=style&index=0&id=58aee878&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "58aee878",
  null
  
)

export default component.exports