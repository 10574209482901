<template>
  <div class="mb-2 mb-md-4 position-relative rounded-bottom z-2">
    <div class="photocredit fs-7" v-if="!templateGroup">© Mareen Fischinger / Westend61</div>
    <template v-if="templateGroup">
      <b-img-lazy :src="require(`@/assets/img/banner/${imgName}.jpg`)" class="object-fit-cover w-100" alt="Job" style="height: 30rem;" />
    </template>
    <template v-else>
      <b-img-lazy :src="require(`@/assets/img/banner/${imgName}.jpg`)" class="object-fit-cover w-100" alt="ABA Job" style="height: 30rem;" />
    </template>
    <div class="position-absolute top-0 start-0 text-white display-1 pt-5 px-5 d-none d-md-flex">
      {{ title }}
    </div>
    <div class="position-absolute bottom-0 d-flex align-items-end p-3 p-md-5 w-100 mb-4 mb-sm-5">
      <slot></slot>
    </div>
  </div>
</template>
<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';
import { AppModule } from '@/store/modules/app';
import { group } from '@/config/configuration';

@Component
export default class BannerComponent extends Vue {
  @Prop(String) readonly imgName: string | undefined

  @Prop(String) readonly title: string | undefined

  @Prop(String) readonly imgUrl: string | undefined

  @Prop(String) readonly page: string | undefined

  get sid() {
    return AppModule.sid;
  }

  get templateGroup() {
    return group(this.sid);
  }
}
</script>
