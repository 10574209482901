<template>
  <b-modal id="modal-contact-profile" :title="translations[397]" hide-footer size="lg" @shown="showModal">
    <template #default="{ hide }">
      <p class="overflow-hidden text-truncate">
        {{ translations[398] }}:
        <span class="fw-bold">
          {{ selectedApplicant }}
        </span>
      </p>
      <b-form @submit.prevent="onSubmit">
        <div class="row g-3">
          <div v-if="accessTokenCompany" class="col-12">
            <h3>{{ translations[770] }}</h3>
            <p v-html="translations[3423]" class="mb-0" />
          </div>
          <div class="col-12">
            <b-form-checkbox class="m-0 pe-0" button-variant="primary" switch size="sm" v-model="directContact.snd_to_app"
              >{{ translations[3424] }}
            </b-form-checkbox>
          </div>
          <div v-if="accessTokenCompany" class="col-12">
            <b-form-select v-model="directContact.job_id" :options="companyJobsMapped" value-field="id">
              <template #first>
                <b-form-select-option :value="null" disabled>{{ translations[1891] }}</b-form-select-option>
              </template>
              <template #default="item">{{ item }}</template>
            </b-form-select>
          </div>
          <div class="col-12 col-md-6">
            <label class="form-label">{{ translations[404] }}:</label>
            <b-form-input size="sm" type="text" v-model="directContact.einsatzort" />
          </div>
          <div class="col-12 col-md-6">
            <label class="form-label">{{ translations[401] }}:</label>
            <b-form-input size="sm" type="text" v-model="directContact.position" />
          </div>
          <div class="col-12">
            <label class="form-label">{{ translations[402] }}:</label>
            <CKEditorWidget :height="100" v-model="directContact.taetigkeit" />
          </div>
          <div class="col-12 col-md-6 d-none">
            <label class="form-label">{{ translations[405] }}:</label>
            <b-form-input size="sm" type="text" />
          </div>
          <div class="col-12 col-md-6">
            <label class="form-label">{{ translations[403] }}:</label>
            <b-form-input size="sm" type="text" v-model="directContact.einstelldatum" />
          </div>
          <div class="col-12 col-md-6">
            <label class="form-label">{{ translations[407] }}:</label>
            <b-form-input size="sm" type="text" v-model="directContact.firma" />
          </div>
          <div class="col-12 col-md-6">
            <label class="form-label">{{ translations[690] }} <code>*</code>:</label>
            <b-form-input required size="sm" type="text" v-model="directContact.ansprech" />
          </div>
          <div class="col-12 col-md-6">
            <label class="form-label">{{ translations[999] }} <code>*</code>:</label>
            <b-form-input size="sm" type="text" v-model="directContact.telefon" />
          </div>
          <div class="col-12 col-md-6">
            <label class="form-label">{{ translations[408] }}:</label>
            <b-form-input size="sm" type="text" v-model="directContact.fadresse" />
          </div>
          <div class="col-12 col-md-6">
            <label class="form-label">{{ translations[688] }} <code>*</code>:</label>
            <b-form-input required size="sm" type="text" v-model="directContact.plzort" />
          </div>
          <div class="col-12 col-md-6">
            <label class="form-label">{{ translations[858] }}:</label>
            <b-form-input required size="sm" type="email" v-model="directContact.email" />
          </div>
          <div class="col-12 col-md-6">
            <label class="form-label">{{ translations[268] }}:</label>
            <b-form-input size="sm" type="text" v-model="directContact.homepage" />
          </div>
          <div class="col-12">
            <label class="form-label">{{ translations[406] }}:</label>
            <b-form-textarea size="sm" rows="5" v-model="directContact.bemerkung" />
          </div>
        </div>
        <div class="d-flex justify-content-end mt-3">
          <b-button type="submit" variant="primary"> {{ translations[313] }} </b-button>
          <b-button variant="primary" class="ml-2" @click="hide"> {{ translations[570] }} </b-button>
        </div>
      </b-form>
    </template>
  </b-modal>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import CKEditorWidget from '@/components/Widget/CKEditorWidget.vue';
import { WorkersModule } from '@/store/modules/workers';
import { postDirectContactApi } from '@/api/Workers';
import { AppModule } from '@/store/modules/app';
import { getCompanyJobs } from '@/api/Company';
import { UserModule } from '@/store/modules/user';

@Component({
  components: {
    CKEditorWidget,
  },
})
export default class WorkerContactModal extends Vue {
  get translations() {
    return AppModule.translation;
  }

  loading = false;

  directContact = {
    applicants: [-1],
    job_id: null,
    position: '',
    einsatzort: '',
    einstelldatum: '',
    gehaltsr: '',
    bemerkung: '',
    ansprech: '',
    firma: '',
    fadresse: '',
    plzort: '',
    telefon: '',
    email: '',
    homepage: '',
    taetigkeit: '',
    snd_to_app: true,
  };

  companyJobs: any[] = [];

  get companyJobsMapped() {
    return this.companyJobs.map((el) => ({
      ...el,
      text: `${el.lastdate}: ${el.position}`,
    }));
  }

  // TokenCompany
  get accessTokenCompany() {
    return UserModule.accessTokenCompany;
  }

  get selectedApplicant() {
    return WorkersModule.selectedApplicantContact;
  }

  async showModal() {
    this.directContact = {
      applicants: [this.selectedApplicant],
      job_id: null,
      position: '',
      einsatzort: '',
      einstelldatum: '',
      gehaltsr: '',
      bemerkung: '',
      ansprech: '',
      firma: '',
      fadresse: '',
      plzort: '',
      telefon: '',
      email: '',
      homepage: '',
      taetigkeit: '',
      snd_to_app: true,
    };

    if (this.accessTokenCompany) {
      const response = await getCompanyJobs();
      this.companyJobs = response.jobs;
    }
  }

  async onSubmit() {
    this.loading = true;
    try {
      await postDirectContactApi(this.directContact);

      this.$nextTick(() => {
        this.$bvModal.hide('modal-contact-profile');
      });

      this.$bvToast.show('success-toast');
    } catch (e) {
      console.error(e);
    }
    this.loading = false;
  }
}
</script>
