import { Component, Vue } from 'vue-property-decorator';
import { TypeField } from '@/types/Login';
import { AppModule } from '@/store/modules/app';

@Component
export default class extends Vue {
  typeField = TypeField;

  get applicantRegistrationFields() {
    return AppModule.setting?.login_tabs?.applicant_registration_fields || [];
  }

  get companyRegistrationFields() {
    return AppModule.setting?.login_tabs?.company_registration_fields || [];
  }

  isField(type: TypeField, name: string, fields: any[]) {
    if (fields?.length) {
      const field = fields.find((x: any) => x.name === name);
      return field ? field[type] : false;
    }
    return false;
  }

  isApplicantField(type: TypeField, name: string) {
    return this.isField(type, name, this.applicantRegistrationFields);
  }

  isCompanyField(type: TypeField, name: string) {
    return this.isField(type, name, this.companyRegistrationFields);
  }
}
