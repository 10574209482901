<template>
  <b-row class="g-2">
    <b-col v-for="item in landingBars" :key="`bars-${item.id}`">
      <b-card no-body class="h-100">
        <b-card-body>
          <h4 v-html="item.label" />
          <p v-html="item.info_text"></p>
        </b-card-body>
      </b-card>
    </b-col>
  </b-row>
</template>

<script lang="ts">
import { AppModule } from '@/store/modules/app';
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class TextInformationBox extends Vue {
  get landingBars() {
    return AppModule.settingPortal.landing_bars;
  }
}
</script>
